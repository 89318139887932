.tableSeachContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
}
.searchButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 2vh;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.searchBarContainer {
  display: flex;
}
.ulContainer {
  display: flex;
}
ul {
  list-style-type: none;
}
li {
  margin: 10px;
}
.searchBarInputBox {
  height: 20px;
  width: 18vw;
  padding-left: 10px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.searchBoxDropDown {
  width: 8vw;
  height: 26px;
}

.createTemplateLink {
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 10px 20px;
  border-radius: 20px;
  text-decoration: none;
  background-color: orange;
  height: 20px;
  padding: 8px 20px;
  margin-right: 20px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
