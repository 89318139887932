.fileInputContainer {
  display: inline-block;
  margin-bottom: 20px;
  width: 60%;
}

.boxContainer {
  display: flex;
  justify-content: space-between;
}

.box1 {
  width: 120px;
  height: 60px;
  color: gray;
  font-weight: bolder;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  color: black;
  background-color: rgba(255, 180, 81, 0.2);
  border: 4px solid orange;
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(224, 147, 47, 0.2),
    0 10px 30px rgba(236, 154, 65, 0.2);
}

.fileInput {
  /* background-color: aquamarine;
  border: 1px solid #ccc; */
  border-radius: 5px;
  padding: 10px 0px 10px 10px;
  font-size: 16px;
  outline: none;
}

.fileInput:hover {
  border-color: #999;
}
