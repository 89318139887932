.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.childContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.gcContainer {
  width: 80%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.record {
  text-align: center;
  align-items: center;
}

h3 {
  color: gray;
}

.rechargeBtn {
  text-decoration: none;
  text-align: center;
  padding: 10px 30px;
  border-radius: 20px;
  font-weight: bold;
  border: none;
  color: white;
  background-color: orange;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
