.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.topContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 10vh;
  border: none;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.searchContainer {
  /* width: 70%; */
}

.createTemplateContainer {
  /* width: 10%; */
  margin-right: 2vw;
}

.campaignInput {
  margin-left: 1.5vw;
  /* width: 30%; */
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.templateSelect {
  margin-left: 2vw;
  /* width: 30%; */
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
}

.bodyContainer {
  margin-top: 2vh;
  width: 90%;
  /* min-height: 66vh; */
  /* min-height: 60vh; */
  border: none;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.sendButton {
  text-decoration: none;
  text-align: center;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bold;
  border: none;
  color: white;
  background-color: orange;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
