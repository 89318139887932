.container {
  padding: 0px 20px;
}

.dropdownContainer {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
