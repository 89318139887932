.nameLanguagecontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nameContainer {
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.nameInput {
  height: 3vh;
  width: "100%";
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.languagesContainer {
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.languagesInput {
  height: 3vh;
  padding-left: 1vw;
}

.submitContainer {
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  display: flex;
  text-align: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 4vh;
  align-items: center;
  justify-content: space-between;
}

.nextButton {
  padding: 10px 40px;
  border-radius: 20px;
  border: none;
  color: white;
  background-color: orange;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
