.container {
  width: 93%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.childContainer {
  text-align: start;
}

.submitContainer {
  width: 96%;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
}

.nextButton {
  text-decoration: none;
  padding: 10px 40px;
  border-radius: 20px;
  border: none;
  color: white;
  background-color: orange;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
