.container {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.childContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40vw;
  height: 60vh;
  border-radius: 4px;
  padding: 20px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
}

.nextButton {
  text-decoration: none;
  font-weight: bolder;
  padding: 10px 40px;
  border-radius: 4px;
  border: none;
  color: white;
  background-color: orange;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

/* LoginHeader.module.css */

.loginHeader {
  color: transparent; /* Set text color to transparent */
  text-align: center;
  font-family: Arial, sans-serif; /* Font family */
  font-size: 3.8em; /* Font size */
  font-weight: bold; /* Font weight */
  margin: 20px 0; /* Margin */
  padding: 10px; /* Padding */
  background: linear-gradient(
    135deg,
    #ffa500,
    #ff6347,
    #ff8c00,
    #ffd700,
    #ff8e7a
  ); /* Gradient background with multiple colors */
  background-size: 400% 100%; /* Set background size */
  background-clip: text; /* Clip text to background */
  -webkit-background-clip: text; /* For Safari compatibility */
  -webkit-text-fill-color: transparent; /* Make text transparent */
  animation: colorChange 20s infinite linear; /* Animation */
  border-radius: 10px; /* Border radius */
  /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3); Shadow */
}

@keyframes colorChange {
  0% {
    background-position: 0 0; /* Start position */
  }
  100% {
    background-position: 400% 0; /* End position */
  }
}
