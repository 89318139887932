.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category {
  width: 80%;
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.marketingChildContainer {
  margin: 10px;
  border-radius: 20px;
  padding: 10px 20px 10px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.nameLanguageContainer {
  width: 80%;
}
