/* table {
  width: 100%;
}
th {
  text-align: left;
  height: 8vh;
}
td {
  height: 6vh;
  text-align: left;
}
.msgTempTableContainer {
  height: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.msgTempTableChildContainer {
  width: 96%;
  height: 40vh;
  padding: 20px;
  overflow: scroll;
} */

.pagination {
  display: flex;
  padding: 10px 0px;
  width: 100%;
  background-color: white;
  justify-content: right;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.paginationButton {
  margin-right: 16px;
  padding: 6px 22px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  color: white;
  /* background-color: rgb(255, 191, 71); */
  background-color: orange;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.msgTempTableContainer {
  height: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding-bottom: 40px;
}

.msgTempTableChildContainer {
  width: 100%;
  height: 40vh;
  overflow-y: scroll; /* Make the container scrollable */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  height: 28px;
  padding: 12px 16px;
  text-align: left;
}

thead {
  /* background-color: rgb(255, 191, 71); */
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  height: 50px;
  z-index: 1;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f0f0f0;
}
