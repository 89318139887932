.container {
  display: flex;
  width: 100%;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.childContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  margin-right: 2vw;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}

.head {
  padding: 10px 0px;
  width: 100%;
  min-height: 6vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: orange;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1vh;
}

.spanHead {
  color: white;
  margin-left: 10px;
}

.header {
  width: 100%;
  /* min-height: 6vh; */
  /* max-height: fit-content; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1vh;
}

.fileInput {
  /* background-color: aquamarine;
  border: 1px solid #ccc; */
  border-radius: 5px;
  padding: 10px 0px;
  font-size: 16px;
  /* outline: none; */
}

.headerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.headerVariableContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.spanHeader {
  font-weight: bold;
  color: grey;
  margin-left: 10px;
  width: 10%;
}

.headerInput {
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-weight: bold;
  margin-right: 1vw;
  width: 72%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
}

.headerImage {
  margin-top: 2vh;
  margin-bottom: 2vh;
  width: 200px;
  height: 200px;
  border-radius: 5px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1vh;
  align-items: center;
  /* justify-content: space-evenly; */
}

.body {
  margin-top: 1vh;
  margin-bottom: 1vh;
  width: 100%;
  /* min-height: 6vh; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* background-color: white;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1vh; */
}

.bodyVarContainer {
  width: 88%;
  display: flex;
  justify-content: space-around;
  /* align-items: flex-start; */
  display: flex;
  flex-wrap: wrap;
}

.spanBody {
  font-weight: bold;
  color: grey;
  margin-left: 1vw;
  width: 10%;
}
.spanBodyVariable {
  font-weight: bold;
  color: grey;
  margin-right: 1vw;
}

.bodyInput {
  margin-top: 1vh;
  margin-bottom: 1vh;
  padding: 10px 20px;
  font-weight: bold;
  margin-right: 1vw;
  width: 72%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
  resize: none;
}

.varbodyInput {
  margin-top: 1vh;
  margin-bottom: 1vh;
  padding: 10px 20px;
  font-weight: bold;
  margin-right: 1vw;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
  resize: none;
}

.footer {
  margin-top: 1vh;
  margin-bottom: 1vh;
  width: 100%;
  /* min-height: 6vh; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1vh;
}

.spanFooter {
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-weight: bold;
  color: grey;
  margin-left: 10px;
  width: 10%;
}

.footerInput {
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-weight: bold;
  margin-right: 1vw;
  width: 72%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
}

.buttons {
  margin-top: 1vh;
  margin-bottom: 1vh;
  width: 100%;
  min-height: 6vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1vh;
}

.saveContainer {
  margin-top: 2vh;
  /* margin-bottom: 1vh; */
  width: 100%;
  /* min-height: 6vh; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  /* box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2); */
  /* margin-bottom: 1vh; */
}

.button {
  text-decoration: none;
  text-align: center;
  padding: 10px 40px;
  border-radius: 20px;
  font-weight: bold;
  border: none;
  color: white;
  background-color: orange;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* margin-right: 1vw; */
}
