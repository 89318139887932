.categoryDialogueBox {
  display: flex;
  align-items: center;
}
.checkbox {
  padding: 20px;
}
.checkboxTitle {
  align-items: baseline;
}
