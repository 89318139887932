.container {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.topPanelContainer {
  margin: 10px;
  width: 100%;
}

.childContainer {
  margin: 10px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
