.container {
  display: flex;
  width: 100%;
  height: 100%;
}

.numberContainer {
  padding: 20px;
  width: 28%;
  height: 100%;
}

.phoneTextArea {
  width: 100%;
  height: 50vh;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  resize: none;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}

.templateContainer {
  width: 70%;
}

.baseInput {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.templateSelect {
  /* margin-left: 2vw; */
  /* width: 60%; */
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.button {
  text-decoration: none;
  text-align: center;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bold;
  border: none;
  color: white;
  background-color: orange;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  /* margin-right: 1vw; */
}
