.urlContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.span {
  width: 10%;
  font-weight: bold;
  color: grey;
}
.textInput {
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-weight: bold;
  margin-right: 1vw;
  width: 20%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
}
.urlInput {
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-weight: bold;
  margin-right: 1vw;
  width: 42%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
}

.phoneContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}
.textphoneInput {
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-weight: bold;
  margin-right: 1vw;
  width: 20%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
}
.phoneNumberInput {
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-weight: bold;
  margin-right: 1vw;
  width: 42%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
}

.qrContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.qrTextInput {
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-weight: bold;
  margin-right: 1vw;
  width: 42%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
}
